import React from "react";
import {Helmet} from "react-helmet";
import moment from 'moment';
import {graphql} from "gatsby";
import Layout from "../layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import PostTags from "../components/PostTags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import dateTime from "../utils/dateTimeFormatter";
import "./post.scss";

export default class PostTemplate extends React.Component {
    formatDate(date) {
        return dateTime(date);
    }

    getImageUrl(fileName) {
        if(!!fileName && fileName.startsWith("http")){
            return fileName
        }
        const base = '/images/covers';
        const finalName = !!fileName ? fileName : '0.jpeg'
        return `${base}/${finalName}`;
    }

    getCover(post) {
        if (!!post.cover) {
            return (<img className="mx-auto d-block img-thumbnail" src={this.getImageUrl(post.cover)} alt={post.title}/>);
        }
        return (<></>);
    }

    render() {
        const {data, pageContext} = this.props;
        const {slug} = pageContext;
        const postNode = data.markdownRemark;
        const post = postNode.frontmatter;
        if (!post.id) {
            post.id = slug;
        }

        return (
            <Layout>
                <div>
                    <Helmet>
                        <title>{`${post.title} | ${config.siteTitle}`}</title>
                    </Helmet>
                    <SEO postPath={slug} postNode={postNode} postSEO/>
                    <div className="blog-post">
                        <div className="post-header">

                            <h1 className="display-1">{post.title}</h1>
                            <p className="lead note">{this.formatDate(post.date)}
                                <PostTags tags={post.tags}/>
                            </p>
                        </div>
                        <div className="post-cover">
                            {this.getCover(post)}
                        </div>
                        <div className="post-body" dangerouslySetInnerHTML={{__html: postNode.html}}/>
                        <div className="post-meta">
                            <SocialLinks postPath={slug} postNode={postNode}/>
                        </div>
                        <UserInfo config={config}/>
                        <Disqus postNode={postNode}/>
                    </div>
                </div>
            </Layout>
        );
    }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            timeToRead
            excerpt
            frontmatter {
                title
                cover
                date
                category
                tags
            }
            fields {
                slug
                date
            }
        }
    }
`;
